import React, { Suspense } from 'react'
import { useRouter } from 'next/router'
import { fetchPageBySlug } from 'lib/hygraph'
import { resolvePageBlocks } from 'utils/query/queryResolver'
import { components } from '~components'
import { SLUG_DOES_NOT_EXIST } from '~constants'
import logger from 'utils/logger-aws'

const Error = (props: any) => {
  const router = useRouter()

  const {
    allPages: { blocks, businessUnit }
  } = props

  const componentsList = blocks.map((block: any, index: number) => {
    const { componentName } = block
    // @ts-ignore
    const DynamicComponent = components[componentName]
    const componentProps = {
      ...block
    }

    if (DynamicComponent) {
      return <DynamicComponent key={index} {...componentProps} router={router} businessUnit={businessUnit} />
    }
  })

  return (
    <>
      {
        // @ts-ignore
        <Suspense fallback={<div>Loading...</div>}>{componentsList}</Suspense>
      }
    </>
  )
}

export async function getStaticProps({ params, preview = false }: any) {
  logger.info('404 page: getStaticPaths has been triggered...')

  const { pages: data }: any = await fetchPageBySlug(false, { slug: SLUG_DOES_NOT_EXIST })

  const pages = await data.map(async (page: any) => {
    const clonedPage = { ...page }
    clonedPage.blocks = await resolvePageBlocks(clonedPage)

    return clonedPage
  })

  logger.info(`404 page: getStaticPaths pages: ${JSON.stringify(pages)}`)

  const [allPages] = await Promise.all(pages)

  return {
    props: {
      allPages
    }
  }
}

Error.propTypes = {}

export default Error
